.footer_body li > a,
.footer_body li {
  color: var(--normal-text-color);
  text-decoration: none;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.footer_body:nth-child(n - 3) li {
  margin: 20px 0;
}

.footer_social_icon:hover {
  color: var(--purple-color) !important;
}

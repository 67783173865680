.nav_container {
  position: relative;
  top: 0;
  width: 100%;
  /* height: 110px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
 /* background-color: #ffffff;
  box-shadow: 0px 20px 30px #5021FE0D;*/
}

.left_items,
.right_items {
  display: flex;
  align-items: center;
}

.left_items img {
  cursor: pointer;
}

.nav_link,
.nav_link_right,
.profile_btn {
  color: var(--normal-text-color);
  background: transparent;
  border: 0;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 18px;
  margin: 0 50px;
}

.nav_link_right {
  color: var(--secondary-text-color);
}

.nav_link_btn {
  color: var(--purple-color);
  font-weight: 600;
  border: 1px solid var(--purple-color);
  /* padding: 1rem 2.3rem; */
  border-radius: var(--border-radius);
  background: transparent;
  height: 3.3rem;
  width: 10rem;
  text-align: center;
}

.nav_link_btn:hover {
  background-color: var(--purple-color);
  color: white;
}

.profile_dropdown {
  position: relative;
  display: inline-block;
}

.profile_dropdown_content {
  display: none;
  position: absolute;
  left: calc(50% - 115px);
  min-width: 230px;
  border-radius: 15px;
  padding: 30px 0 0 0;
  background-color: #ffffff;
  box-shadow: 0px 10px 35px #5021fe0d;
  z-index: 4;
}

.profile_dropdown_content button:nth-child(5) {
  border-top: 2px solid #e7e7e7;
}

.profile_dropdown_item {
  color: black;
  text-decoration: none;
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 10%;
}

.profile_dropdown_item:hover {
  color: var(--red-text-color);
}

.profile_dropdown:hover .profile_dropdown_content {
  display: block;
}

.profile_dropdown:hover .profile_dropdown_btn {
  background-color: #3e8e41;
}

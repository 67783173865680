* {
  margin: 0;
  padding: 0;
  font-family: "Popins", sans-serif;
  /* font-size: 14px; */
}
.App {
  color: #2d2d2d;
  scroll-behavior: smooth;
  background-color: #f8f9ff;
  /* background-color: #f8f9ff; */
}

.fade {
  opacity: 1;
  /* transform: translateY(20px); */
}

:root {
  --purple-color: #5021fe;
  --secondary-text-color: #2d2d2d;
  --normal-text-color: #848484;
  --red-text-color: #ff4d01;
  --quaternary-color: #ffffff;
  --input-bg-color: #f6f6f9;
  --pop-up-bg-color: #1f0482;
  --border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c9c9c9;
}

.cursor_pointer {
  cursor: pointer;
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: var(--purple-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.form-control {
  border: 1px solid #d9d9d9;
  padding: 10px 15px;

  color: #2d2d2d !important;
  border-radius: var(--border-radius);
  background-color: #f6f6f9;
}

.form-control input[type="file" i] {
  border-radius: var(--border-radius) !important;
}

.form-control:focus {
  box-shadow: none !important;
  background-color: #f6f6f9;
}

.form-select {
  border: 1px solid #d9d9d9;
  padding: 8px 15px !important;
  font-size: 18px;
  color: #6b6974 !important;
  border-radius: var(--border-radius) !important;
  background-color: var(--input-bg-color);
}

.form-select:focus {
  box-shadow: none !important;
}

.color-purple {
  color: var(--purple-color);
}

.form-check-input {
  border: 2px solid #c9c9c9;
  font-size: 18px !important;
  padding: 0.6rem !important;
  color: #2d2d2d !important;
  border-radius: 5px !important;
  background-color: var(--input-bg-color) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--purple-color) !important;
}

.custom_input {
  border: none;
  padding: 10px 20px !important;
  font-size: 18px !important;
  color: #2d2d2d !important;
  border-radius: 36px;
  background-color: var(--input-bg-color) !important;
}

.custom_input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.primary_color_text {
  color: var(--purple-color);
  font-family: "Popins", sans-serif;
  font-weight: 600;
}

.secondary_color_text {
  color: var(--secondary-text-color);
  font-family: "Popins", sans-serif;
  font-weight: 600;
}
.grey_color_text {
  color: #b1b1b1;
  font-family: "Popins", sans-serif;
}

.fourteen_px_text {
  font-size: 14px !important;
}

.sixteen_px_text {
  font-size: 16px !important;
}

.eighteen_px_text {
  font-size: 18px !important;
}
.font-light {
  color: #848484;
}
.font-light-text {
  color: #a1a1a2;
}
.font-blue {
  color: #5021fe !important;
}
.font-info {
  color: #ffffff99;
}
.w-95 {
  width: 95%;
}

.bg-white {
  background-color: #ffffff;
}

.rounded-20 {
  border-radius: 20px;
}
.p-6 {
  padding: 4.5rem;
}
.p-7 {
  padding: 6rem;
}
.p-8 {
  padding: 7.5rem;
}

label {
  font-family: "Popins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary-text-color);
}

.purple_btn {
  border: 1px solid var(--purple-color);
  background-color: var(--purple-color);
  color: #ffffff;
  font-weight: 600;
  border-radius: 10px;
}

.purple_btn:hover {
  background-color: transparent;
  border: 1px solid var(--purple-color);
  color: var(--purple-color);
}

.purple_btn:focus {
  background-color: transparent;
  border: 1px solid var(--purple-color);
  color: var(--purple-color);
}

.purple_btn:active {
  background-color: transparent;
  border: 1px solid var(--purple-color);
  color: var(--purple-color);
}

.orange_btn {
  background-color: var(--red-text-color);
  border-radius: 36px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Popins", sans-serif;
  border: none;
  width: 50%;
  padding: 0.9rem 0;
}

.reverse_purpler_btn {
  border-radius: 6px;
  background-color: #ede8ff;
  color: var(--purple-color);
  padding: 5px 12px;
}

.reverse_purple_border_btn {
  border: 1px solid var(--purple-color);
  background-color: transparent;
  color: var(--purple-color);
  border-radius: 5px;
}

.reverse_purple_border_btn:hover {
  background-color: var(--purple-color);
  color: #ffffff;
}

.transparent_btn {
  background: transparent;
  border: none;
  color: var(--purple-color);
  font-family: "Popins", sans-serif;
  font-size: 14px;
  font-weight: 550;
  text-align: right;
}

.white_btn {
  background: #ffffff;
  border: none;
  font-family: "Popins", sans-serif;
  font-weight: 600;
  text-align: center;
  border-radius: var(--border-radius);
}
.white_btn:hover {
  background: #ffffff;
}

.btn-close {
  border-radius: 50% !important;
  padding: 0.5rem !important;
  box-shadow: 0px 5px 22px #0000001a;
  /* margin: calc(-6 * var(--bs-modal-header-padding-y))
    calc(0 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto !important; */
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  z-index: 3;
}

.site_name {
  color: var(--purple-color);
}

.modal-header {
  justify-content: center;
  border-bottom: none;
  padding: 0;
}

.modal-title {
  font-family: "Popins", sans-serif;
  font-size: 26px;
  font-weight: 600 !important;
  /* color: var(--secondary-text-color); */
  margin-top: 2rem;
}

.modal-title p {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: var(--normal-text-color);
  text-align: center;
}

.custom_modal_size {
  --bs-modal-width: 600px;
}

.modal-content {
  border-radius: 20px !important;
}

.progress-bar {
  background-color: var(--red-text-color) !important;
  border-radius: 5px !important;
}

.page-padding {
  padding: 5rem;
}

.border-purple {
  border: var(--purple-color) 1px solid;
  border-radius: var(--border-radius);
}

.text-purple {
  color: var(--purple-color);
}

.text-grey {
  color: var(--normal-text-color);
}
.bg-grey {
  background: #f9f9f9;
}

[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 991;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 990;
  padding: 8px;
  width: 200px;
  background-color: rgb(255, 255, 255);
  background-color: hsla(0, 0%, 100%, 0.9);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: rgb(0, 0, 0);
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #ffffff;
  border-top-color: hsla(0, 0%, 100%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: rgb(255, 255, 255);
  border-left-color: hsla(0, 0%, 100%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-color: hsla(0, 0%, 100%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}

.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-35 {
  font-size: 35px;
}

.fs-46 {
  font-size: 46px;
}

.line_grey {
  height: 0.5px;
  background: var(--normal-text-color);
}

.PhoneInputInput {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--normal-text-color);
  background: transparent;
}

hr {
  border: 2px solid #e5e5e5;
}

.hr {
  border: 1px solid #e5e5e5;
  width: 20%;
  margin: auto;
}

.PhoneInputCountryIcon--border {
  border: none !important;
  box-shadow: none !important;
}

.PhoneInputCountrySelect[disabled] {
  background: azure;
}

/* loader */
.loader {
  --path: #2f3545;
  --dot: #5628ee;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 0;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  border: 1px solid #dbdbdb;
  border-radius: 0;
  background-color: #ffffff;
  margin-top: 48px;
}

.suggestion-item,
.suggesion-item-active {
  border-top: 1px solid #dbdbdb;
  padding: 10px;
}

hr {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .modal-title {
    font-size: 20px !important;
  }
}

.login_form input {
  border: none;
}

.login_line {
  display: block;
  content: "";
  border: 1px solid #b1b1b1;
  width: 100px;
  margin: 1rem auto;
  opacity: 0.3;
}

.linkedin_circle {
  border-radius: 50%;
  background-color: #007ab9;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.other_signin_container {
  background-color: transparent;
  border: 1px solid #b1b1b1;
  border-radius: 36px;
  padding: 0.8rem;
  cursor: pointer;
}

.other_signin_container:hover {
  border: 1px solid var(--purple-color);
}

.orLine {
  display: flex;
  flex-direction: row;
  font-family: "Popins", sans-serif;
  font-style: normal;
  font-weight: 600;
}

.orLine::before,
.orLine::after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid var(--secondary-text-color);
  margin: 0.5em 2rem;
}

.terms_section {
  font-family: "Popins", sans-serif;
  font-style: normal;
}

.terms_section a {
  color: var(--purple-color);
  font-weight: 500;
  cursor: pointer;
}

.page_container {
  padding: 5rem 18%;
}

.page_content_container {
  border-radius: 20px;
  padding: 2rem !important;
  box-shadow:0px 20px 30px #5021FE0D;
}

.profile_header {
  padding: 1rem 0 2rem 0 !important;
  border-bottom: 2px solid #e5e5e5;
}

.profile_image_container {
  width: 134px;
  height: 134px;
  border-radius: 50%;
  border: 2px solid var(--purple-color);
  padding: 0.2rem;
}

.profile_image_container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile_user_title {
  font-size: 26px;
  font-weight: 600;
  color: var(--purple-color);
}

.profile_edit_btn {
  font-size: 22px;
  font-weight: 600;
  color: var(--purple-color);
  background: transparent;
  border: none;
}

.profile_body_title h1 {
  font-size: 32px;
}

.profile_body_sub_title h3 {
  font-size: 22px;
}

.add_btn {
  background: transparent;
  border: none;
  font-size: 18px;
  color: var(--purple-color);
}

.purple_text_btn {
  background: transparent;
  border: none;
  font-size: 22px;
  font-weight: 600;
  color: var(--purple-color);
}

.video_resume_upload {
  background-color: #f9f8ff;
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.employer_profile_container {
  background-color: #f8f9ff;
}

.employer_profile_title {
  width: 680px;
}

.employer_profile_info_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  width: 680px;
}

.profile_upload_container {
  background: #f9f8ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .employer_profile_info_container,
  .employer_profile_title {
    width: 100%;
  }

  .employer_profile_container {
    padding: 0 1rem!important;
  }

  .profile_upload_container {
    width: 100%!important;
  }
}


.home_top_section_title h1 {
  font: normal normal 600 4.3vw/5.3vw "Poppins", sans-serif;
  /* font-size: 4.3vw; */
  color: var(--secondary-text-color);
}

.home_top_section_title h3 {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 2.7vw;
  color: var(--red-text-color);
}

.home_search_box {
  background-color: #ffffff;
  padding: 1rem;
  font-size: 21px;
  color: var(--normal-text-color);
  border-radius: var(--border-radius);
  overflow-x: auto;
}

.home_search_box select {
  border: 0;
  opacity: 0.5;
}

.home_search_box input {
  border: 0;
  opacity: 0.5;
}

.home_search_box input:focus {
  outline: none;
}

.home_search_box select:focus {
  outline: none;
}

.line {
  display: block;
  content: "";
  border: 2px solid #b1b1b1;
  border-radius: var(--border-radius);
  width: 151px;
  margin: 4rem auto;
  opacity: 0.3;
}

.home_candidate span,
.home_employer span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: black;
}

.home_resume_box,
.home_employer_box {
  border: 2px solid #a1a1a2;
  border-radius: 85px;
  width: 455px;
  padding: 1rem;
  cursor: pointer;
}

.home_resume_box:hover,
.home_employer_box:hover {
  border: 2px solid var(--purple-color);
}

.home_resume_box:hover span,
.home_employer_box:hover span {
  color: var(--purple-color);
}

.home_resume_box:hover p,
.home_employer_box:hover p {
  color: var(--purple-color);
}

.home_resume_text span,
.home_employer_text span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 29px;
  color: black;
}

.home_resume_text p,
.home_employer_text p {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: var(--normal-text-color);
}

.home_middle_section_title h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.7vw;
  color: #2d2d2d;
}

.home_middle_section_title span {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 1.57vw;
  color: #2d2d2d;
  opacity: 0.5;
  cursor: pointer;
}

.home_middle_section_title span:hover {
  text-decoration: underline;
}

.home_middle_section_cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px;
  place-items: center;
}

.home_middle_section_card {
  width: 100%;
  background-color: #ffffff;
  border-radius: var(--border-radius);
  padding: 2rem;
  cursor: pointer;
  border: 1px solid #c9c9c9;
}

.home_middle_section_card:hover {
  border: 1px solid var(--purple-color);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.home_middle_section_card_icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_middle_section_card_icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.3vw;
  color: #2d2d2d;
  opacity: 0.5;
}

.top_arrow {
  color: #a1a1a2;
}

.home_middle_section_card:hover .top_arrow {
  color: var(--purple-color);
}

.home_middle_section_card_title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2vw;
  color: #232a45;
  line-height: 1.3;
}

.home_bottom_section {
  padding: 50px;
  border-radius: 20px;
}

.home_bottom_section_left span {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 34px;
  color: #2d2d2d;
}
.home_bottom_section_left h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 78px;
  color: #2d2d2d;
}

.home_bottom_section_right_form_container {
  width: 510px;
  background: #f6f3ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  /* opacity: 0.7; */
  padding: 30px;
}

.home_bottom_section_right_form_container button {
  opacity: 1;
}

.home_bottom_section_right_form_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: var(--border-radius);
  padding: 0.5rem 1.3rem;
}

.home_bottom_section_right_form_input_container input {
  width: 450px;
  /* height: 77px; */
  border: 0;
  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 21px;
  color: var(--normal-text-color);
  width: 100%;
}

.home_bottom_section_right_form_input_container input:focus {
  outline: none;
}

.candidate_search_container,
.employer_search_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  width: 48%;
}
.candidate_search_container h1,
.employer_search_container h1 {
  color: #2d2d2d;
  font-weight: 550 !important;
}
.disabled-box
{
  pointer-events:none;
  opacity:.5;
}

.job_container {
  border-bottom: 3px solid #f6f6f9;
}

.job_title_container {
  border-right: 3px solid #f6f6f9;
}

.custom_job_modal_body {
  border: 1px solid var(--purple-color);
  border-radius: 1rem;
}

.modal_left_job_details {
  border: 1px solid var(--purple-color);
  border-radius: 1rem;
}

.home_industry_dropdown {
  text-align: start;
  position: relative;
  display: inline-block;
}

.home_industry_dropdown:hover {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: none;
}

.home_industry_dropdown_content {
  display: none;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  margin-top: 13px;
  border: 1px solid #d9d9d9;
  border-top: none;
  width: 300px;
  height: 300px;
  overflow-y: scroll;
  z-index: 3;
}

.home_industry_dropdown_content::-webkit-scrollbar {
  width: 5px;
}

.home_industry_dropdown:hover #show {
  display: block;
}

.home_location_search {
  height: 50px;
  text-align: start;
  position: relative;
  display: inline-block;
}

#show_search {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: none;
}

.form-select-picky {
  border: 1px solid #d9d9d9;
  padding: 3px !important;
  font-size: 16px !important;
  color: #6b6974 !important;
  border-radius: var(--border-radius) !important;
  background-color: var(--input-bg-color);
}

.form-select-picky:focus {
  box-shadow: none !important;
}
.picky__input {
  border-width: 0px !important;
  color: #6b6974 !important;
  height: auto;
}
.picky__input::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  right: 5px;
}
.picky__placeholder {
  display: inline-block;
  margin-right: 8px;
}
.picky__placeholder button {
  line-height: normal;
}

.form-select::after {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  right: 16px;
  top: 16px;
}
